import http from '@/common/http-common';
import { GOOGLE_LOGIN_BY_TOKEN, ISAFE_LOGIN } from '@/common/constant';

class AuthService {
  iSafeLogin(data) {
    return http.post(ISAFE_LOGIN, data);
  }

  googleLogin(token) {
    return http.get(`${GOOGLE_LOGIN_BY_TOKEN}/${token}`)
  }

}

export default new AuthService();
