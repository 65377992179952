import http from "@/common/http-common"
import { BASE_EXPORT_PAYMENTS } from "@/common/constant";

class PaymentProcessService {
  export(params) {
    return http.get(BASE_EXPORT_PAYMENTS.concat(localStorage.getItem('bearer_token')),{
      params: params,
      responseType: 'blob',
    });
  }

}
export default new PaymentProcessService();
