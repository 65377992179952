import Vue from "vue";
import Vuex from "vuex";

// import health_monitoring from "./modules/health_monitoring";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // health_monitoring
  }
});
