<template>
  <div class="main-content">
    <!-- HEADER -->
    <b-overlay id="overlay-background" :show="loading" variant="transparent" opacity="1" blur="2px"
                          rounded="sm">
    <div class="header">
      <div class="container-fluid">
        <div v-if="role == null" class='col-md-12'>
          <div class='alert alert-warning' role='alert'><strong>
            <span class='fe fe-alert-triangle'></span>&nbsp&nbsp&nbsp<small>This user has not assign into any role
            yet, please contact administrator for more information
          </small>
          </strong>
          </div>
        </div>
        <div class="card shadow mt-5">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">

                <!-- Avatar -->
                <a href="#!" class="avatar">
                  <span class="fe fe-user" style="font-size: 3em;"></span>
                </a>

              </div>
              <div class="col ms-n2">

                <!-- Title -->
                <h2 class="mb-2">Hello, {{ name }}</h2>

                <!-- Text -->
                <p class="text-muted mb-md-0">
                  Do not forget to submit your payments
                </p>
                <label v-if="role != null" class="btn bg-danger-soft mt-2" for="option1" @click="goToNewPayment">
                  <span class="fe fe-upload"></span>&nbsp; Submit Payments
                </label>
                <a class="btn bg-warning-soft mt-2 ms-2" href="https://ugems.id/user-guide">
                  <span class="fe fe-info"></span>&nbsp; Quick Guide
                </a>
              </div>
            </div> <!-- / .row -->
          </div>
        </div>
        <!-- Body -->
      </div>
    </div>
    <!-- / .header -->
    <!-- CARDS -->
    <div class="container-fluid">

      <h3 class="mb-4">
        Progress Realtime
      </h3>
      <hr>


      <div class="col-md-12">
        <div class="tile_count">
          <div class="row">
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover
                 title="Payment request yang diajukan user request, exclude terminated" @click="goToNewPayment">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-user"></i> User Request</div>
              <div class="count">{{ countRequest }}</div>
              <!--              <span class="count_bottom"><i class="green">4% </i> From last Week</span>-->
            </div>

            <!-- WAITING DOC SENT -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Menunggu kirim dokumen 2x24 jam')"
                 @click="goToNewPaymentByCode('not-sent-doc')">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Menunggu kirim dokumen 2x24 jam</div>
              <div class="count">{{ countNotSentDoc }}</div>
              <!-- <span v-if="countNotSentDoc > 0"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ countNotSentDoc }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span> -->
            </div>

            <!-- PENDING -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Need Send Doc (Reject By System)')"
                 @click="goToSpecificPayment(['pending-review-document-control'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Need Send Doc (Reject By System)</div>
              <div class="count">{{ countTotal(['Konfirmasi Detail Pengiriman Hardcopy Document (By System)']) }}</div>
              <span v-if="getMatch('Konfirmasi Detail Pengiriman Hardcopy Document (By System)') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Konfirmasi Detail Pengiriman Hardcopy Document (By System)') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>


            <!-- <div class="col-md-2 tile_stats_count" v-for="item in countGroupRequest"
                 v-if="item.title.toLowerCase().includes('review')" v-b-tooltip.hover :title="wording(item.title)"
                 @click="goToSpecificPayment(item.title)">
              <span class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> {{
                  shorten(item.title)
                }}</span>
              <div class="count">{{ item.count }}</div>
              <span v-if="getMatch(item.title) != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch(item.title) }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div> -->
            <!-- DOC CONTROL -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Review document control')"
                 @click="goToSpecificPayment(['review-doc-control', 'payment-unmatched', 'tax-unmatched'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Review doc control</div>
              <div class="count">{{ countTotal(['Review document control', 'Unmatched']) }}</div>
              <span v-if="getMatch('Review document control') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Review document control') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>

          </div>
          <div class="row">

            <!-- BUDGET -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Review budget')"
                 @click="goToSpecificPayment(['review-budget', 'budget-revise-req-accounting'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Review Budget</div>
              <div class="count">{{ countTotal(['Review budget', 'Permintaan revisi budget oleh accounting']) }}</div>
              <span v-if="getMatch('Review budget') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Review budget') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>

            <!-- TAX -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Review tax')"
                 @click="goToSpecificPayment(['review-tax', 'tax-revise-req-accounting', 'tax-revise-req-treasury'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Review Tax</div>
              <div class="count">{{ countTotal(['Review tax', 'Permintaan revisi tax oleh accounting', 'Permintaan revisi tax oleh treasury']) }}</div>
              <span v-if="getMatch('Review tax') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Review tax') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>

            <!-- ACCOUNTING -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Review accounting')"
                 @click="goToSpecificPayment(['review-accounting', 'accounting-revise-req-treasury'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Review Accounting</div>
              <div class="count">{{ countTotal(['Review accounting', 'Permintaan revisi accounting oleh treasury']) }}</div>
              <span v-if="getMatch('Review accounting') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Review accounting') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>

            <!-- TREASURY -->
            <div class="col-md-3 tile_stats_count" v-b-tooltip.hover :title="wording('Review treasury')"
                 @click="goToSpecificPayment(['review-treasury'])">
              <div class="count_top text-muted text-uppercase"><i class="fe fe-file-text"></i> Review Treasury</div>
              <div class="count">{{ countTotal(['Review treasury']) }}</div>
              <span v-if="getMatch('Review treasury') != null"
                    class="badge bg-danger-soft mt-n1"><i class="fe fe-clock"></i> {{ getMatch('Review treasury') }}</span>
              <span v-else class="badge bg-success-soft mt-n1">No Outstanding</span>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang menunggu hardcopy dikirimkan"
               @click="goToNewPaymentByCode('waiting-doc-sent')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg  bg-info"></div>
                  <i class="h2 text-white fe fe-archive mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countHardcopy }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Hardcopy to be submitted</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request dimana hardcopy telah diterima, namun masih menunggu pembayaran"
               @click="goToNewPaymentByCode('payment-received')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-warning"></div>
                  <i class="h2 text-white fe fe-clock mb-0"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countOutstanding }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Outstanding Payment</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang sudah sudah dibayarkan oleh FA" @click="goToNewPaymentByCode('paid')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-success"></div>
                  <i class="h2 text-white  fe fe-check-square mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countCompletion }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Payment Completion</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang menunggu hardcopy dikirimkan"
               @click="goToNewPaymentByCode('doc-sent')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg  bg-danger"></div>
                  <i class="h2 text-white fe fe-archive mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countReceivedHardcopy }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Received Hardcopy To Be Submitted</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang di-reject oleh role Budget dan payment ini tidak dapat diproses di sistem FAMS"
               @click="goToNewPaymentByCode('payment-terminated')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-secondary"></div>
                  <i class="h2 text-white fe fe-trash-2 mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countTerminate }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Terminated Payment</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang sedang dalam proses revisi oleh user"
               @click="goToSpecificPayment(['revise-req-doc-control', 'revise-req-budget', 'revise-req-tax', 'revise-req-accounting', 'revise-req-treasury'])">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-primary"></div>
                  <i class="h2 text-white fe fe-edit-3 mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countTotal(['Permintaan revisi oleh document control', 'Permintaan revisi oleh budget', 'Permintaan revisi oleh tax', 'Permintaan revisi oleh accounting', 'Permintaan revisi oleh treasury']) }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">Revision To User</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="role == 'budget' || role == 'administrator'" class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment request yang di hold"
               @click="goToNewPaymentByCode('budget-review-on-hold')">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-secondary"></div>
                  <i class="h2 text-white fe fe-pause mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countOnHold }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">ON HOLD BY BUDGET</h5>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="role == 'doc-control' || role == 'administrator'" class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment before 14 Feb 2024"
               @click="goToOldFlowPayment()">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-info"></div>
                  <i class="h2 text-white fe fe-arrow-left-circle mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countOldFlow }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">OLD FLOW</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-if="role == 'doc-control' || role == 'administrator'" class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment after 14 Feb 2024"
               @click="goToNewFlowPayment()">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-success"></div>
                  <i class="h2 text-white fe fe-arrow-right-circle mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countNewFlow }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">NEW FLOW</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="role == 'doc-control' || role == 'treasury' || role == 'treasury-head' || role == 'administrator'" class="col" style="margin-left: 0px">
          <div class="card shadow invoice-card" v-b-tooltip.hover
               title="Payment fast track"
               @click="goToFastTrackPayment()">
            <div class="card-body d-flex" style="padding: 15px">
              <div class="icon me-3">
                <div class="icon-wrapper rounded-circle">
                  <div class="icon-wrapper-bg bg-primary"></div>
                  <i class="h2 text-white fe fe-fast-forward mb-0" style="font-size: 1.5em;"></i>
                </div>
              </div>
              <div>
                <span class="invoice-num">
                    <strong>{{ countFastTrack }}</strong>
                </span>
                <!--                <span class="text-white fs-18"></span>-->
                <h5 class="text-muted text-uppercase mb-2 title-card">FAST TRACK</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col" style="margin-left: 0px">
        </div>
        <div class="col" style="margin-left: 0px">
        </div>

      </div>
      <!-- / .row -->
      <div v-if="role != 'user'" class="col-12 col-xl-12 mb-4">
        <!-- Convertions -->
        <h3 class="mb-4">
          Monthly Report {{ upperCaseRole(role) }}
        </h3>
        <hr>
        <div class="row">
          <Datepicker range v-model="filterDate" lang="id" placeholder="Pilih Tanggal" circle
                  input-class="form-control  mt-2" />
          <div class="col-lg-6">
            <!-- Card -->
            <button class="btn btn-lg bg-danger-soft mt-2" @click="exportPayments">
              <span class="fe fe-download"></span>&nbsp; Export Document Report
            </button>
          </div>
        </div>
        <hr>
      </div>
      <div class="row">
        <div class="col-xl-4 ">
          <!-- Projects -->
          <div class="card shadow min-content">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Latest PO Submit Invoice</h4>

            </div>
            <div class="card-body">
              <div class="col=sm-12">
              <!-- List group -->
              <div class="list-group list-group-flush list-group-activity my-n3">
                <div v-if="poLimit2.length < 1">
                  No Data Available
                </div>
                <div v-else class="list-group-item" v-for="item in poLimit2">
                  <div class="row">
                    <div class="col-sm-2">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <span class="avatar-title rounded-circle bg-info">PO</span>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <!-- Heading -->

                      <!-- Text -->
                      <h5 class="mb-1">
                        <span class="badge bg-warning-soft mt-n1"> {{ item.displayId }} </span>
                      </h5>
                      <div class="col-sm-12 small text-gray-700" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                        {{ item.vendorName }}
                      </div>

                      <!-- Time -->
                      <!--                      <small class="text-muted"> Mon, 2 Jan 02.00 PM </small>-->
                      <!--                      YYYY-MM-DD HH: mm: ss-->
                      <small class="text-muted"> {{ moment(item.createdAt).format('ddd, DD MMM HH:mm:ss') }}</small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
          </div>
            <!-- / .card-body -->
          </div>
          <!-- / .card -->
        </div>
    <!--        <div class="clearfix"></div>-->

        <div class="col-xl-4 ">
          <!-- Projects -->
          <div class="card shadow min-content">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Latest Non-PO Submit Invoice</h4>

              <!-- Link -->
            </div>
            <div class="card-body">
              <div class="col=sm-12">
              <!-- List group -->
              <div class="list-group list-group-flush list-group-activity my-n3">
                <div v-if="nonPoLimit2.length < 1">
                  No Data Available
                </div>
                <div v-else class="list-group-item" v-for="item in nonPoLimit2">
                  <div class="row">
                    <div class="col-sm-2">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <span class="avatar-title rounded-circle bg-success">NP</span>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <!-- Heading -->

                      <!-- Text -->
                      <h5 class="mb-1">
                        <span class="badge bg-warning-soft mt-n1"> {{ item.displayId }} </span>
                      </h5>
                      <div class="col-sm-12 small text-gray-700" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                        {{ item.vendorName }}
                      </div>
                      <!-- Time -->
                      <!--                      <small class="text-muted"> Mon, 2 Jan 02.00 PM </small>-->
                      <small class="text-muted"> {{ moment(item.createdAt).format('ddd, DD MMM HH:mm:ss') }}</small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
            </div>
            <!-- / .card-body -->
          </div>
          <!-- / .card -->
        </div>
        <div class="col-xl-4">
          <!-- Traffic -->
          <div class="card shadow">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title">Overall Invoice</h4>

              <!-- Tabs -->
            </div>
            <div class="card-body">
              <!-- Chart -->
              <div id="chart">
                <apexchart type="donut" :options="charts" :series="seriess"></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </b-overlay>
  </div>
  <!-- / .main-content -->
</template>
<script>
import "../../assets/js/theme.bundle";
import "../../assets/js/vendor.bundle";
import UserService from "@/services/UserService";
import PaymentService from "@/services/PaymentService";
import PaymentExportService from "@/services/PaymentExportService";
import moment from "moment/moment";
import PaymentProcessService from "@/services/PaymentProcessService";

export default {
  name: "awokComponent",
  data() {
    return {
      loggedUser: JSON.parse(localStorage.getItem('logged_user')),
      user: null,
      role: null,
      direct: false,
      comp: false,
      seriess: [],
      charts: {},
      name: null,

      nonPoLimit2: [],
      poLimit2: [],
      countRequest: 0,
      countTerminate: 0,
      countHardcopy: 0,
      countReceivedHardcopy: 0,
      countCompletion: 0,
      countOutstanding: 0,
      countOnHold: 0,
      countNotSentDoc: 0,
      countGroupRequest: [],
      countGroupChart: [],
      countFastTrack: 0,
      countOldFlow: 0,
      countNewFlow: 0,
      longestWait: [],
      backupCount: [],
      paymentProcesses: null,
      filterDate: [null,null],
      loading: false
    };
  },
  methods: {

    retrievePaymentsPO(role) {
      const params = new URLSearchParams();
      params.append('payment_type', 'PO')
      params.append('page', 1);
      params.append('per_page', 3);
      if (role == 'user') {
        params.append('user_id', this.loggedUser.id)
        params.append('exclude_payment_process_id', 'ImVuY29kZWQgMzMi')
      }
      //  else if (role != 'administrator' && role != 'user') {
      //   params.append('all_divisions', true)
      // }
      PaymentService.getAll(params)
          .then(response => {
            this.poLimit2 = response.data.results
          })
    },

    retrievePaymentsNonPO(role) {
      const params = new URLSearchParams();
      params.append('payment_type', 'NON_PO')
      params.append('page', 1);
      params.append('per_page', 3);
      if (role == 'user') {
        params.append('user_id', this.loggedUser.id)
        params.append('exclude_payment_process_id', 'ImVuY29kZWQgMzMi')
      } 
      // else if (role != 'administrator' && role != 'user') {
      //   params.append('all_divisions', true)
      // }
      PaymentService.getAll(params)
          .then(response => {
            this.nonPoLimit2 = response.data.results
          })
    },

    count(role) {
      const params = new URLSearchParams();
      if (role == 'user' || role == 'viewer') {
        params.append('user_id', this.loggedUser.id)
        params.append('exclude_payment_process_id', 'ImVuY29kZWQgMzMi')
      } else if (role != 'administrator' && role != 'user' && role != 'viewer') {
        params.append('all_divisions', true)
      }
      PaymentService.count(params)
          .then(response => {
            this.countRequest = response.data
          })
    },

    searchObjByCode(arr, key) {
      console.log('searchObjByCode')
      console.log({arr, key})
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].workflowTaskId === key) {
          return arr[i].id;
        }
      }
    },

    goToNewPaymentByCode(code) {
      if(this.role != null){
        if(code === 'not-sent-doc') {
          const id = this.searchObjByCode(this.paymentProcesses, 'review-doc-control')
          return this.$router.push({
            path: `payments/`,
            query: { paymentProcessIds:id, onlyNotSentDoc: true }
          });
        }
        const id = this.searchObjByCode(this.paymentProcesses, code)
        return this.$router.push({
          path: `payments/`,
          query: {paymentProcessIds: id}
        });
      }
    },

    goToFastTrackPayment() {
      if(this.role != null){
        return this.$router.push({
          path: `payments/`,
          query: {fastTrack: true}
        });
      }
    },
    goToOldFlowPayment() {
      if(this.role != null){
        return this.$router.push({
          path: `payments/`,
          query: {
                  oldFlow: true,
                  filterDate: '20220101-20240213'
                }
        });
      }
    },
    goToNewFlowPayment() {
      var date = moment();
      var currentDate = date.format('YYYYMMDD');
      if(this.role != null){
        return this.$router.push({
          path: `payments/`,
          query: {
                  newFlow: true,
                  filterDate: '20240214-'+currentDate
                }
        });
      }
    },

    countTerminateRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id);
      }
      //payment-terminated
      const obj = this.searchObjByCode(this.paymentProcesses, 'payment-terminated')

      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countTerminate = response.data
          })
    },

    countFastTrackRequest() {
      const params = new URLSearchParams();

      params.append('fast_track', true)
      PaymentService.count(params)
          .then(response => {
            this.countFastTrack = response.data
          })
    },

    countOldFlowRequest() {
      const params = new URLSearchParams();

      params.append('since', '2022-01-01')
      params.append('until', '2024-02-13')
      PaymentService.count(params)
          .then(response => {
            this.countOldFlow = response.data
          })
    },

    countNewFlowRequest() {
      const params = new URLSearchParams();
      var date = moment();
      var currentDate = date.format('YYYY-MM-DD');

      params.append('since', '2024-02-14')
      params.append('until', currentDate)
      PaymentService.count(params)
          .then(response => {
            this.countNewFlow = response.data
          })
    },

    countHardcopyRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id)
      }
      //waiting-doc-sent
      const obj = this.searchObjByCode(this.paymentProcesses, 'waiting-doc-sent')
      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countHardcopy = response.data
          })
    },

    countReceivedHardcopyRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id)
      }
      //doc-sent
      const obj = this.searchObjByCode(this.paymentProcesses, 'doc-sent')
      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countReceivedHardcopy = response.data
          })
    },

    countCompletionRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id);
      }
      //paid
      const obj = this.searchObjByCode(this.paymentProcesses, 'paid')
      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countCompletion = response.data
          })
    },

    countOutstandingRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id);
      }
      //payment-received
      const obj = this.searchObjByCode(this.paymentProcesses, 'payment-received')
      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countOutstanding = response.data
          })
    },

    countGroupByPaymentProcess(role) {
      this.countGroupRequest = []
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id);
      } else {
        params.append('all_divisions', true);
      }
      PaymentService.countGroupByPaymentProcess(params)
          .then(response => {
            const map = new Map(Object.entries(response.data));
            for (let [key, value] of map.entries()) {
              let form = {
                title: key,
                count: value
              }
              this.countGroupRequest.push(form)
              this.backupCount.push(form)
            }
          })
      if (role === 'user') {
        this.countAllChart()
      }
    },

    countonHoldRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user') {
        params.append('user_id', this.loggedUser.id);
      }
      //payment-on-hold
      const obj = this.searchObjByCode(this.paymentProcesses, 'budget-review-on-hold')

      params.append('payment_process_id', obj)
      PaymentService.count(params)
          .then(response => {
            this.countOnHold = response.data
          })
    },

    countNotSentDocRequest(role) {
      const params = new URLSearchParams();
      if (role == 'user') {
        params.append('user_id', this.loggedUser.id);
      }
      // // payment-on-hold
      const obj = this.searchObjByCode(this.paymentProcesses,'review-doc-control')

      params.append('payment_process_id', obj)
      params.append('only_not_sent_doc', 'true')
      PaymentService.count(params)
          .then(response => {
            this.countNotSentDoc = response.data
          })
    },
    countTotal(titles) {
      // Find the total count for the given titles.
      let total = 0;
      for (const title of titles) {
        const index = this.countGroupRequest.findIndex(item => item.title.toLowerCase() === title.toLowerCase());
        if (index !== -1) {
          total += this.countGroupRequest[index].count;
        }
      }
      // Return the total count.
      return total;
    },

    countGroupByuserDivision(role) {
      this.countGroupRequest = []
      const params = new URLSearchParams();
      if (role == 'user'|| role == 'viewer') {
        params.append('user_id', this.loggedUser.id);
      }
      params.append('all_divisions', true)
      PaymentService.countGroupByUserDivision(params)
          .then(response => {
            let series = []
            let title = []
            const map = new Map(Object.entries(response.data));
            for (let [key, value] of map.entries()) {
              series.push(value)
              title.push(key)
            }
            this.seriess = series;
            this.charts = {
              chart: {
                type: 'donut',
              },
              labels: title,
              legend: {
                show: true,
                position: 'bottom',
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 100,
              },
              responsive: [{
                breakpoint: 480,
              }],
              dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]
                },
              },
            }

          })
    },

    countAllChart() {
      setTimeout(() => {
        const title = ['My Request', 'Outstanding Payment', 'Hard Copy To Be Submitted', 'Terminated Payment']
        const series = [this.countRequest, this.countOutstanding, this.countHardcopy, this.countTerminate]
        const obj = JSON.parse(JSON.stringify(this.backupCount))
        obj.map(req => {
          if (req.title.toLowerCase().includes('review')) {
            title.push(req.title)
            series.push((req.count))
          }
        })
        this.seriess = series;
        this.charts = {
          chart: {
            type: 'donut',
          },
          labels: title,
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 100,
          },
          responsive: [{
            breakpoint: 480,
          }],
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]
            },
          },
        }
      }, 400);
    },

    longestWaitGroup(role) {
      this.countGroupRequest = []
      const params = new URLSearchParams();
      params.append('group_field', 'createdAt')
      params.append('group_type', 'MIN')
      params.append('all_division', true)
      if (role == 'user') {
        params.append('user_id', this.loggedUser.id);
      }
      PaymentService.countGroupByPaymentProcess(params)
          .then(response => {
            const map = new Map(Object.entries(response.data));
            for (let [key, value] of map.entries()) {
              let timeProcess = null
              if (value != null) {
                const timeFormatted = moment(value).locale('id').format('YYYYMMDDHHmmss');
                timeProcess = moment(timeFormatted, 'YYYYMMDDHHmmss').fromNow();
              }
              let form = {
                title: key,
                time: timeProcess
              }
              this.longestWait.push(form)

            }
          })
    },

    upperCaseRole(role){
      return role?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    },

    exportPayments() {
      const params = new URLSearchParams();
      // params.append('payment_type', 'PO')
      if (this.roleCode != 'user'|| role == 'viewer') {
        params.append('all_divisions', true);
      } else {
        params.append('user_id', JSON.parse(localStorage.logged_user).id);
      }
      if (this.filterDate[1]) {
        const since = moment(this.filterDate[0]).locale('id').format('YYYY-MM-DD');
        const until = moment(this.filterDate[1]).locale('id').format('YYYY-MM-DD');
        params.append('since', since);
        params.append('until', until);
      }
      this.$swal({
        title: 'Are you sure',
        text: "You want to export following payment?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#A12528',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading= true
          PaymentExportService.export(params).then(response => {
            this.loading= false
            const blob = new Blob([response.data], {
              type: 'text/csv',
            });
            var FileSaver = require('file-saver');
            FileSaver.saveAs(blob, `Payments_${JSON.parse(localStorage.logged_user).email}.xlsx`);
            this.$swal("Saved", "Your payment has been exported", "success")
          }).catch(e => {
            this.loading= false
            this.$swal("Failed", "Your payment field is failed to export with following error: " + e.response.data.message, "error")
            if(e.response.status == 504 || e.response.status == 502){
              return this.$router.push({
                path: `/maintenance`,
              });
            }
          })
        }
      })
    },

    getMatch(key) {
      const obj = this.searchObj(key, this.longestWait)
      return obj
    },

    searchObj(key, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].title.toLowerCase() === key.toLowerCase()) {
          return arr[i].time;
        }
      }
    },

    goToNewPayment() {
      if(this.role != null){
      this.$router
          .push({path: 'payments',}).then(() => {
        this.$router.go()
      })
    }
    },

    searchPaymentProcess(arr, key) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].workflowTaskId.toLowerCase() === key.toLowerCase()) {
          return arr[i].id;
        }
      }
    },

    goToSpecificPayment(workflowTaskId) {
      if(this.role != null){
        let ids = []
        for(const wfId of workflowTaskId){
          let id = this.searchPaymentProcess(this.paymentProcesses, wfId)
          console.log(wfId +" - " + id)
          ids.push(id)
        }
      return this.$router.push({
        path: `payments/`,
        query: {paymentProcessIds: ids.join(',')}
      });
      }
    },
    wording(item) {
      var state = item.replace('Review ', '')
      let value = state.toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      const str = `Payment request yang sedang dalam proses review oleh role ${value}`
      return str;
    },

    shorten(item) {
      let val = item
      if (item.toLowerCase().includes('document control')) {
        val = 'Review doc control'
      }
      return val
    },

    retrievePaymentProcesses() {
      PaymentProcessService.getAll()
          .then(response => {
            this.paymentProcesses = response.data
          })
          .catch(e => {
            console.log(e);
          });
    },

  },
  mounted() {
    this.$loading.show({delay:0})
    this.retrievePaymentProcesses()
    UserService.get(this.loggedUser.id)
        .then(response => {
          this.user = response.data
          this.name = response.data.name
          this.role = response.data.role.code
          this.longestWaitGroup(this.role)
          this.count(this.role)
          this.countGroupByPaymentProcess(this.role)
          this.countTerminateRequest(this.role)
          this.countHardcopyRequest(this.role)
          this.countOutstandingRequest(this.role)
          this.countCompletionRequest(this.role)
          this.countReceivedHardcopyRequest(this.role)
          this.countonHoldRequest(this.role)
          this.countNotSentDocRequest(this.role)
          this.retrievePaymentsPO(this.role)
          this.retrievePaymentsNonPO(this.role)
          this.countFastTrackRequest()
          this.countOldFlowRequest()
          this.countNewFlowRequest()

          if (this.role != 'user') {
            this.countGroupByuserDivision(this.role)
          }
        })
        .catch(e => {
          console.log(e);
        });
    this.$loading.hide()
  }
};
</script>
<style>

.icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: -3px 8px 16px -4px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: -3px 8px 16px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -3px 8px 16px -4px rgba(0, 0, 0, 0.17);
}

.icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  /*z-index: 3;*/
  opacity: .7;
}


.icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
}

.rounded-circle {
  border-radius: 50% !important;
}

.card .shadow {
  box-shadow: -4px 9px 14px -1px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: -4px 9px 14px -1px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: -4px 9px 14px -1px rgba(0, 0, 0, 0.32);
}

.invoice-card {
  border-left-color: #d63737;
  cursor: pointer;
}

.tile_count {
  margin-bottom: 20px;
  margin-top: 20px
}

.tile_stats_count {
  border-bottom: 1px solid #D9DEE4;
  padding: 0 10px 0 20px;
/*  text-overflow: ellipsis;*/
  overflow: hidden;
/*  white-space: nowrap;*/
  position: relative;
  cursor: pointer
}

@media (min-width: 992px) {
  .tile_stats_count {
    margin-bottom: 10px;
    border-bottom: 0;
    padding-bottom: 10px
  }
}

.tile_stats_count:before {
  content: "";
  position: absolute;
  left: 0;
  height: 65px;
  border-left: 2px solid #ADB2B5;
  margin-top: 10px
}

@media (min-width: 992px) {
  .tile_stats_count:first-child:before {
    border-left: 0
  }
}

.tile_stats_count .count {
  font-size: 30px;
  line-height: 47px;
  font-weight: 600
}

@media (min-width: 768px) {
  .tile_stats_count .count {
    font-size: 40px
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .tile_stats_count .count {
    font-size: 30px
  }
}

.tile_stats_count span {
  font-size: 11px
}

@media (min-width: 768px) {
  .tile_stats_count span {
    font-size: 13px
  }
}

.tile_stats_count .count_bottom i {
  width: 12px
}

.title-card {
  font-size: 15px;
}

.invoice-num {
  font-size: 30px
}

.min-content {
  min-height: min-content;
  white-space: nowrap;
}

.count_top{
  font-size: 15px;
  min-height: 2lh;
  height: 2lh;
  display: inline-block;
}

</style>
