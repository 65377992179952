<template>
  <div class='login-page'>
    <transition name='fade'>
      <div v-if='!registerActive' class='wallpaper-login'></div>
    </transition>
    <div class='wallpaper-register'></div>

    <div class='container'>
      <div class='row'>
        <div class='col-lg-4 col-md-6 col-sm-8 mx-auto'>
          <div class="card">
            <div class="card-body">
              <img src="./../assets/img/FAMS.png" class="mx-auto center mb-5" width="200px" alt="...">
              <p class='text-center mt-2 bold'>Login to continue</p>
              <!-- Form -->
              <form @submit.prevent="doLogin">
                <div class="form-group">
                  <label for="iSafeNo" class="form-label">iSafe Number</label>
                  <input v-model='isafeNumberLogin' type="text" class="form-control" id="iSafeNo"
                         placeholder="Enter iSafe number"/>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1" class="form-label">Password</label>
                  <input v-model='passwordLogin' type="password" class="form-control" id="password"
                         placeholder="Enter Password"/>
                </div>
                <!-- s -->
                <!-- Button -->
                <button type="submit" class="form-control btn btn-primary mb-3">Login</button>
                <p style='text-align: center;'>Or continue with</p>
              </form>
              <div class="signup-buttons">
                <GoogleLogin :params='params' :onSuccess='onSignInSuccess' :onFailure='onSignInError'
                             class="google-signup form-control btn btn-outline-secondary mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true">
                    <title>Google</title>
                    <g fill="none" fill-rule="evenodd">
                      <path fill="#4285F4"
                            d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z">
                      </path>
                      <path fill="#34A853"
                            d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z">
                      </path>
                      <path fill="#FBBC05"
                            d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z">
                      </path>
                      <path fill="#EA4335"
                            d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z">
                      </path>
                    </g>
                  </svg>
                  &nbspLogin with Google
                </GoogleLogin>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import GoogleLogin from 'vue-google-login';
import alert from "bootstrap/js/src/alert";

export default {
  name: 'HelloWorld',
  data() {
    return {
      registerActive: false,
      isafeNumberLogin: '',
      passwordLogin: '',
      emptyFields: false,
      isAuthorized: true,
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        // client_id: '667331643080-biaqum7espmq9e39qabjo078bs6hfjvn.apps.googleusercontent.com'
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        plugin_name: process.env.VUE_APP_GOOGLE_PLUGIN_NAME,
      },
      routeActive: this.$route.params.url == '/login' ? null : this.$route.params.url
    };
  },
  components: {
    GoogleLogin,
  },
  mounted() {
    this.checkSession();
  },
  methods: {
    checkSession() {
      const token = localStorage.getItem('bearer_token');
      if (token) {
        if (this.routeActive != null) {
          this.$router.push(this.routeActive).catch(() => {
          });
        } else {
          this.$router.push('/');
        }
      }
    },
    onSignInSuccess(googleUser) {
      this.isAuthorized = true
      AuthService.googleLogin(googleUser.getAuthResponse(true).id_token)
          .then(response => {
            localStorage.setItem("bearer_token", response.data.jwtToken);
            localStorage.setItem("logged_user", JSON.stringify(response.data.user));
            if (this.routeActive != null) {
              this.$router.push(this.routeActive).catch(() => {
              });
            } else {
              this.$router.push('/').then(n => {
                this.$router.go(n)
              })
            }
          }).catch(e => {
        console.log('OOPS');
        this.$swal("Oops!", e.response.data.message, "error");
        if(e.response.status == 504 || e.response.status == 502){
          return this.$router.push({
            path: `/maintenance`,
          });
        }
      })
      ;
    },
    onSignInError(error) {
      console.log('OOPS');
      // `error` contains any error occurred.
      // console.log('OH NOES', error)
      this.isAuthorized = false
    },

    doLogin() {
      if (this.isafeNumberLogin === '' || this.passwordLogin === '') {
        this.emptyFields = true;
      } else {
        AuthService.iSafeLogin({
          username: this.isafeNumberLogin,
          password: this.passwordLogin
        }).then(response => {
          localStorage.setItem("bearer_token", response.data.jwtToken);
          localStorage.setItem("logged_user", JSON.stringify(response.data.user));
          if (this.routeActive != null) {
            this.$router.push(this.routeActive).then(() => {
              this.$router.go()
            }).catch(() => {
            });
          } else {
            this.$router.push('/').then(() => {
              this.$router.go()
            })
          }

        })
            .catch(e => {
              console.log(e);
              this.$swal("Failed!", e.response.data.message, "error");
              if(e.response.status == 504 || e.response.status == 502){
                return this.$router.push({
                  path: `/maintenance`,
                });
              }
            });
      }
    },
  },
};
</script>

<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
  width: 100%;
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
