import http from "@/common/http-common"
import { BASE_PAYMENT_PROCESS, BASE_PAYMENT_PROCESS_PERMISSION } from "@/common/constant";

class PaymentProcessService {
  getAll() {
    return http.get(BASE_PAYMENT_PROCESS);
  }

  getPaymentProcessPermission(){
    return http.get(BASE_PAYMENT_PROCESS_PERMISSION);
  }

}
export default new PaymentProcessService();