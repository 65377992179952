import http from "@/common/http-common"
import { BASE_USERS } from "@/common/constant";

class UserService {
  getAll(params) {
    return http.get(BASE_USERS,  {
      params: params
    });
  }

  get(id) {
    return http.get(`${BASE_USERS}/${id}`);
  }

  upsert(data) {
    return http.post(BASE_USERS, data);
  }

  delete(id) {
    return http.delete(`${BASE_USERS}/${id}`);
  }

  getSyncStatus() {
    return http.get(`${BASE_USERS}/sync-status/sap`);
  }

  upload(userId, data) {
    let formData = new FormData();
    formData.append("file", data);
    console.log(data)
    return http.post(`${BASE_USERS}/signature/${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getFile(userId) {
    return http.get(`${BASE_USERS}/signature/${userId}`, {
      responseType: 'blob'
    });
  }

  getAreaTypes() {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'SITE',
          name: 'SITE'
        },
        {
          id: 'HO',
          name: 'HO'
        },
      ]);
    })
  }


}
export default new UserService();
